import {useEffect, useState} from 'react';

import {Paper, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as Sentry from '@sentry/react';
import {useWindowSize} from '@uidotdev/usehooks';
import {models} from 'powerbi-client';
import {PowerBIEmbed} from 'powerbi-client-react';
import {shape, string} from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';

import {
  POWER_BI_REPORT_FILTERS,
  POWER_BI_REPORT_BUTTONS,
  POWER_BI_PAGES,
  POWER_BI_SLICERS,
  POWER_BI_REPORT_BOOKMARKS,
  DEFAULT_PROJECTS_SCHEMAS,
  REPORT_LOADING_STEPS,
  POWER_BI_PROJECT_SLICER_NAME
} from '../../const';
import useChatbot from '../../hooks/providers/useChatbot';
import useProjects from '../../hooks/providers/useProjects';
import useReport from '../../hooks/providers/useReport';
import useReportSummary from '../../hooks/providers/useReportSummary';
import {
  deleteAllFiltersFromLocalStorage,
  generateUniqueIdFromProjectName,
  saveFilterToLocalStorage,
  saveCurrentProjectToLocalStorage,
  initializeFiltersFromLocalStorage,
  userWantsToSaveFilters,
  resetReportFilters,
  closeAllFiltersBookmarks,
  applyDefiPreviForecastTreshold,
  setProjectFilterSlicer,
  getReportIframeHeight,
  getVisualsUserCanSendThroughChatbot,
  isMobile
} from '../../utils';
import Button from '../form/buttons/Button/Button';
import BaseModal from '../modal/BaseModal';
import DefiPreviButtonsAndModals from './DefiPreviButtonsAndModals';
import useEmbedReportLoadingTracking from './hooks/useEmbedReportLoadingTracking';
import ExportDataLoadingSpinner from './loading/ExportDataLoadingSpinner';
import FiltersLoadingSpinner from './loading/FiltersLoadingSpinner';
import ReportLoadingSpinner from './loading/ReportLoadingSpinner';
import LoadReportFiltersModal from './LoadReportFiltersModal';
import EditableReportButtons from './report-buttons/EditableReportButtons';
import DefiGestionButtons from './report-buttons/EmbedReportDefiGestionButtons';
import SaveEditedReportModal from './SaveEditedReportModal';

// eslint-disable-next-line complexity
const EmbedReport = ({reportConfig, module, lastPageBeforeReload}) => {
  const {
    setSelectedReport,
    setSelectedReportId,
    selectedReport,
    userCanUploadVisuals,
    setUserCanUploadVisuals,
    setIsSpreadsheetModalOpen,
    setSelectedBudget,
    selectedProject,
    setSelectedProject,
    isReportInEditionMode,
    setIsReportInEditionMode,
    setIsEditableReportSaveAsModalOpen,
    reportSelectedPage,
    setReportSelectedPage,
    isReportEditable,
    filtersInitialized,
    setFiltersInitialized,
    filtersLoading,
    setFiltersLoading,
    shouldApplyPreviousFiltersStepCompleted,
    setIsApplyPreviousFiltersModalOpen,
    setShouldApplyPreviousFiltersStepCompleted,
    isDesktopLayout,
    currentBookmark,
    setCurrentBookmark,
    reports
  } = useReport();
  const {getSummaryTheme, setSummaryTheme} = useReportSummary();

  const {projects, fetchProjects} = useProjects();
  const {isChatbotSidebarOpen, setSendableReportVisuals, isNavigatingToOtherReportPageToExportData} = useChatbot();

  const [isLoadingHidden, setIsLoadingHidden] = useState(false);
  const [isSlicerLoading, setIsSlicerLoading] = useState(false);
  const isProjectSlicerLoading = selectedProject && isSlicerLoading;

  const [isLoading, setIsLoading] = useState(true);
  const [reportHasEncounteredFatalError, setReportHasEncounteredFatalError] = useState(false);
  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [shouldFetchSummaryTheme, setShouldFetchSummaryTheme] = useState(false);
  const [reportLoadingStep, setReportLoadingStep] = useState(REPORT_LOADING_STEPS.REPORT_LOADING);
  const [isFullScreenMode, setIsFullscreenMode] = useState(false);

  const isReportLoaded = !isProjectSlicerLoading && isLoadingHidden;

  const location = useLocation();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  useEmbedReportLoadingTracking({
    isLoading,
    isLoadingHidden,
    isProjectSlicerLoading,
    selectedReport,
    selectedProject,
    filtersInitialized,
    filtersLoading,
    isSlicerLoading,
    selectedProjectName
  });

  useEffect(() => {
    const isLandscapeLayout = reportConfig.settings && reportConfig.settings.layoutType === models.LayoutType.Master;
    const isMobileDevice = isMobile();
    if (isLandscapeLayout && isMobileDevice && selectedReport && location.pathname.startsWith('/reports')) {
      console.log('orientation changed');

      setIsFullscreenMode(true);
    } else {
      setIsFullscreenMode(false);
    }
  }, [selectedReport, reportConfig.settings, location.pathname]);

  const redirectToReportHomepage = async () => {
    const report = reports.find(r => r.report_id === reportConfig.id);
    const reportHomepage = report?.homepage;
    await setProjectFilterSlicer(selectedReport, selectedProject.project_name, module);

    if (reportHomepage) {
      await selectedReport.setPage(reportHomepage);
    }
    setIsLoadingHidden(true);
  };

  const endLoading = () => {
    setIsLoading(false);
    setIsLoadingHidden(true);
  };

  const onVisualClicked = event => {
    const data = event.detail;
    if (data.visual.name === POWER_BI_SLICERS.chargesTabs.id) {
      setShouldFetchSummaryTheme(true);
    }
  };

  const onError = event => {
    // eslint-disable-next-line no-console
    console.error({reportError: event});
    const error = event.detail;
    endLoading();

    // If the error level isn't Fatal, log the error and continue.
    if (error.level !== models.TraceType.Fatal) {
      return;
    }

    // If the Fatal error is TokenExpired, refresh the token.
    // Embed token has expired, we regenerate it via loadReports (make an API call to /get-embed-token)
    if (error.message === models.CommonErrorCodes.TokenExpired) {
      // eslint-disable-next-line no-console
      console.warn('EMBED TOKEN EXPIRED');

      setReportHasEncounteredFatalError(true);
    } else {
      setReportHasEncounteredFatalError(true);

      const isDevelopment = process.env.NODE_ENV === 'development';
      if (!isDevelopment) {
        Sentry.captureException(error);
      }
    }
  };

  // eslint-disable-next-line complexity
  function onDataSelected(event) {
    // eslint-disable-next-line no-console
    console.log({event});

    const data = event.detail;
    const isReportHomePage = data.page.name === POWER_BI_PAGES.reportHome.id;
    const isReportChargesPage = data.page.name === POWER_BI_PAGES.reportCharges.id;

    let reportFilterChanged = POWER_BI_REPORT_FILTERS.filter(f => f.title === data.visual.title);
    // Sometimes, two slicers have the exact same title, so we have to compare title AND name of slicer
    reportFilterChanged = reportFilterChanged?.length > 1 ? POWER_BI_REPORT_FILTERS.find(f => f.name === data.visual.name) : reportFilterChanged[0];

    // When we are on filters page, we save every change made
    // If user is switching project from slicer, we redirect to home page
    if (reportFilterChanged?.title === POWER_BI_PROJECT_SLICER_NAME) {
      const projectName = data.dataPoints[0].identity[0].equals;
      saveCurrentProjectToLocalStorage(data);
      setSelectedProjectName(projectName);
    } else if (reportFilterChanged) {
      // We found a filter that we want to save (they are all stored in POWER_BI_REPORT_FILTERS)
      saveFilterToLocalStorage(data, reportFilterChanged);
    }

    // When we are on report page : "Mon chiffre d'affaires" , we only save changes made to "cumul / mensuel" filter
    if (isReportHomePage && reportFilterChanged?.name === '0e64f0ded2f1ee9474ce') {
      saveFilterToLocalStorage(data, reportFilterChanged);
    }

    // When we are on report page : "Mes charges", we only save changes made to "cumul / mensuel" filter OR filter "Ecart / Ratio CA"
    if (isReportChargesPage && reportFilterChanged?.name === 'a01bfbe906056a03786c') {
      saveFilterToLocalStorage(data, reportFilterChanged);
    }

    // When we are on project choice page, we listen to data change in order to set project in localStorage
    if (data.visual.name === POWER_BI_SLICERS.projectChoice.id) {
      saveCurrentProjectToLocalStorage(data);
    }

    // When a user picks a budget scenario in Defi Previ > Budget Page > Slicer budget, we store it.
    // This is used in EmbedReportDefiPreviButtons component to enable/disable edit budget button
    if (data.visual.name === POWER_BI_SLICERS.chooseBudget.id) {
      setSelectedBudget(data.dataPoints[0].identity[0].equals);
    }
  }

  const onButtonClicked = event => {
    // eslint-disable-next-line no-console
    console.log({event});

    const data = event.detail;
    const resetFiltersButton = POWER_BI_REPORT_BUTTONS.resetFilters;
    if (data.id === resetFiltersButton.id && data.bookmark === resetFiltersButton.bookmark) {
      deleteAllFiltersFromLocalStorage();
    }
  };

  const onPageChanged = async event => {
    // eslint-disable-next-line no-console
    const page = event.detail.newPage;
    setReportSelectedPage(page);

    // Business rule : user can start uploading files inside chatbot if :
    // - We are on a DeFi report, and he has landed on home page`
    const isDeFiModule = module === DEFAULT_PROJECTS_SCHEMAS.gestion || module === DEFAULT_PROJECTS_SCHEMAS.previ;
    const reportHomepage = reports.find(r => r.report_id === reportConfig.id)?.homepage;
    const isDeFiHomepage = isDeFiModule && page.name === reportHomepage;
    if (isDeFiHomepage) {
      setUserCanUploadVisuals(true);
    }
  };

  const onBookmarkApplied = event => {
    // eslint-disable-next-line no-console
    console.log({event});
    const data = event.detail;
    setCurrentBookmark(data.bookmarkName);
    if (data.bookmarkName === POWER_BI_REPORT_BOOKMARKS.ratio.name || data.bookmarkName === POWER_BI_REPORT_BOOKMARKS.ecarts.name) {
      const bookmarkSelected = data.bookmarkName;
      localStorage.setItem('bookmark', bookmarkSelected);
    }
  };

  const onSavedAs = event => {
    // eslint-disable-next-line no-console
    console.log({event});
    setIsEditableReportSaveAsModalOpen(true);
  };

  // This hook fetches projects if necessary (not fetched before and it's a DeFi Previ OR DeFi Gestion report)
  useEffect(() => {
    (async () => {
      const moduleRequiresProjectsFetching = module === DEFAULT_PROJECTS_SCHEMAS.previ || module === DEFAULT_PROJECTS_SCHEMAS.gestion;
      const shouldFetchProjects = (projects || []).length === 0;

      if (moduleRequiresProjectsFetching && shouldFetchProjects) {
        await fetchProjects();
      }
    })();
  }, [module]);

  useEffect(() => {
    (async () => {
      if (selectedProjectName) {
        const project = projects?.find(p => p.project_name === selectedProjectName && p.schema_name === module);
        setSelectedProject(project);
      }
    })();
  }, [selectedProjectName]);

  useEffect(() => {
    (async () => {
      if (shouldFetchSummaryTheme) {
        try {
          const theme = await getSummaryTheme(selectedReport);
          setShouldFetchSummaryTheme(false);
          setSummaryTheme(theme);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          Sentry.captureException(e);
        }
      }
    })();
  }, [shouldFetchSummaryTheme]);

  useEffect(() => {
    (async () => {
      try {
        if (!isLoading && selectedReport && lastPageBeforeReload) {
          await selectedReport.setPage(lastPageBeforeReload.name);
          await setProjectFilterSlicer(selectedReport, selectedProject.project_name, module);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        Sentry.captureException(e);
      }
    })();
  }, [selectedReport, isLoading]);

  // This hook sets project in localStorage when user comes from projects page
  useEffect(() => {
    if (selectedProject) {
      localStorage.setItem('project', generateUniqueIdFromProjectName(selectedProject?.project_name));
    }
  }, [selectedProject]);

  // This hook sets the report in loading state when we change report
  // Eg: from url 'reports/1234' to 'reports/5678'
  useEffect(() => {
    setIsLoading(true);
    setIsLoadingHidden(false);
    setFiltersInitialized(false);
    setSendableReportVisuals([]);
    setUserCanUploadVisuals(false);
    setSelectedBudget(null);
    setReportLoadingStep(REPORT_LOADING_STEPS.REPORT_LOADING);
    setSelectedProjectName(null);
  }, [location.pathname]);

  // This hooks handles change on Project slicer in report
  // When user selects a new project, he's redirected on report's CA page
  useEffect(() => {
    (async () => {
      setIsSpreadsheetModalOpen(false);
      if (selectedReport && selectedProject && !isLoading) {
        await redirectToReportHomepage();
        setFiltersInitialized(false);
        if (module === DEFAULT_PROJECTS_SCHEMAS.gestion) {
          const bookmarks = await selectedReport.bookmarksManager.getBookmarks();
          await resetReportFilters(selectedReport);
          await closeAllFiltersBookmarks(selectedReport, bookmarks);
        }
        setShouldApplyPreviousFiltersStepCompleted(false);
      }
    })();
  }, [selectedProject?.siren]);

  // This hooks handles all report filters initialization from local storage
  useEffect(() => {
    // eslint-disable-next-line complexity
    (async () => {
      if (!selectedReport || module !== DEFAULT_PROJECTS_SCHEMAS.gestion) return; // TODO maybe one day we'll want to save/apply filters for other module than DeFi Gestion

      const projectId = localStorage.getItem('project');

      const pages = await selectedReport.getPages();

      const isReportHomePage = reportSelectedPage.name === POWER_BI_PAGES.reportHome.id;
      const isReportChargesPage = reportSelectedPage.name === POWER_BI_PAGES.reportCharges.id;

      const hasFiltersSaved = Object.keys(localStorage).some(key => key.startsWith(`${selectedReport.config.id}.${projectId}`));

      console.log({hasFiltersSaved, localStorage});
      if (!hasFiltersSaved) {
        setFiltersInitialized(true);
        return;
      }

      const userChoiceOnSavingFilters = userWantsToSaveFilters();
      if (!filtersInitialized && !shouldApplyPreviousFiltersStepCompleted && !isLoading && isReportHomePage && userChoiceOnSavingFilters === null) {
        setIsApplyPreviousFiltersModalOpen(true);
        return;
      }

      // When user has chosen not to initialize report from previous report and checked the "do not ask me again" checkbox
      if (Boolean(userChoiceOnSavingFilters) === false && userChoiceOnSavingFilters !== null) {
        return;
      }

      // When we are on homepage for the first time,we initialize report with filters if there is any saved in local storage
      // Then, we redirect to filters page
      if (isReportHomePage && !filtersInitialized) {
        setFiltersLoading(true);
        const homePage = pages.filter(p => p.displayName === POWER_BI_PAGES.reportHome.title)[0];
        const homePageVisuals = await homePage.getVisuals();
        await initializeFiltersFromLocalStorage(selectedReport, homePageVisuals);
        try {
          setTimeout(async () => {
            setFiltersInitialized(true);
            setFiltersLoading(false);
          }, 3000);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          Sentry.captureException(e);
        }
      }

      // When we land on report filters page, we initialize filters if there is any saved in local storage
      if (isReportChargesPage) {
        const chargesPage = pages.filter(p => p.displayName === POWER_BI_PAGES.reportCharges.title)[0];
        const chargesPageVisuals = await chargesPage.getVisuals();
        await initializeFiltersFromLocalStorage(selectedReport, chargesPageVisuals);
        const chargesBookmark = localStorage.getItem('bookmark');
        if (chargesBookmark) {
          await selectedReport.bookmarksManager.apply(chargesBookmark);
        }
      }
    })();
  }, [reportSelectedPage, shouldApplyPreviousFiltersStepCompleted]);

  // This hook handles when user comes from project page.
  // It automatically sets the chosen project in project choice page
  useEffect(() => {
    (async () => {
      if (selectedReport && !isLoading && selectedProject) {
        try {
          setReportLoadingStep(REPORT_LOADING_STEPS.PROJECT_BOOKMARK_APPLYING);
          setIsSlicerLoading(true);

          if (lastPageBeforeReload || module === null) {
            setIsSlicerLoading(false);
          }

          if (!lastPageBeforeReload && module !== null) {
            try {
              await redirectToReportHomepage();
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error(e);
              Sentry.captureException(e);
            } finally {
              setIsSlicerLoading(false);
            }
          }
        } catch (e) {
          setIsSlicerLoading(false);
          // eslint-disable-next-line no-console
          console.error(e);
          Sentry.captureException(e);
        }
      }
    })();
  }, [isLoading, selectedReport]);

  // Deselecting or "Select all" actions on a filter are not firing dataSelected event sometimes, so we cannot save the filter changes when this happens.
  // This hook is a workaround to handle this issue (see below links)
  // https://community.powerbi.com/t5/Issues/Power-BI-Embedded-dataSelected-event-not-triggered/idi-p/876349
  // https://community.powerbi.com/t5/Developer/SelectAll-is-not-firing-dataSelectedEvent-in-first-instance/m-p/2075654)
  // The aim here is to save all filters data when users leaves the report page to be sure all is sync between report's state and localStorage saved filters
  useEffect(() => {
    const interval = setInterval(async () => {
      if (selectedReport && filtersInitialized && isReportLoaded) {
        const pages = await selectedReport.getPages();
        const currentPage = pages.find(p => p.isActive);
        const currentPageVisuals = (await currentPage.getVisuals()).filter(visual => visual.type === 'slicer');

        // eslint-disable-next-line no-restricted-syntax
        for (const visual of currentPageVisuals) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const state = await visual.getSlicerState();
            const projectId = localStorage.getItem('project');
            const defaultVisualSlicer = POWER_BI_REPORT_FILTERS.find(f => f.title === visual.title);
            const localStorageItemName = `${visual.page.report.config.id}.${projectId}.${defaultVisualSlicer.name}`;
            const filterSaved = localStorage.getItem(localStorageItemName);
            if (state.filters.length === 0 && filterSaved) {
              localStorage.removeItem(localStorageItemName);
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            // console.log({e});
            Sentry.captureException(e);
          }
        }
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [selectedReport, filtersInitialized, reportSelectedPage]);

  // Hook to set forecast threshold (only in Defi Previ reports)
  useEffect(() => {
    (async () => {
      const bookmarksTriggeringRegeneration = [POWER_BI_REPORT_BOOKMARKS.impositionTabDefiPrevi.name];
      if (module === DEFAULT_PROJECTS_SCHEMAS.previ && bookmarksTriggeringRegeneration.includes(currentBookmark)) {
        await applyDefiPreviForecastTreshold(reportSelectedPage, currentBookmark, selectedProject.threshold);
      }
    })();
  }, [currentBookmark, selectedProject]);

  // Hook to get all report visuals and extract the ones user can send through copilot
  useEffect(() => {
    (async () => {
      if (selectedReport && userCanUploadVisuals) {
        try {
          const sendableVisuals = await getVisualsUserCanSendThroughChatbot(selectedReport, module);
          setSendableReportVisuals(sendableVisuals);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    })();
  }, [selectedReport, userCanUploadVisuals]);

  // Business rule : user can start uploading files inside chatbot if :
  // - We are on a custom report (!module)
  useEffect(() => {
    (async () => {
      if (selectedReport && !module && location.pathname.startsWith('/reports')) {
        setUserCanUploadVisuals(true);
      }
    })();
  }, [selectedReport]);

  // Hook to reset edit button state when switching report or switching route
  useEffect(() => {
    (async () => {
      if (selectedReport) {
        setIsReportInEditionMode(false);
      }
    })();
  }, [selectedReport, location.pathname]);

  // TODO edit this ? Because now DeFi Gestion has buttons in some cases
  const reportHasButtons = module === DEFAULT_PROJECTS_SCHEMAS.previ || isReportEditable;

  // eslint-disable-next-line complexity
  const getCssClassname = () => {
    if (isFullScreenMode && isReportLoaded) {
      return 'report-fullscreen';
    }

    if (!isReportLoaded || filtersLoading || isNavigatingToOtherReportPageToExportData) {
      return selectedReport?.config?.settings?.layoutType === models.LayoutType.MobilePortrait ? 'report-hidden-mobile' : 'report-hidden';
    }
    let cssClass = 'report-embed';

    if (reportHasButtons) {
      cssClass += ' with-buttons';
    }

    if (isChatbotSidebarOpen) {
      cssClass += ` is-chatbot-sidebar-open${isDesktopLayout ? '' : '-mobile'}`;
    }

    // Custom reports are a bit larger in height so we apply a custom class
    if (!module && isReportInEditionMode) {
      cssClass += ' custom-report';
    }

    return cssClass;
  };

  const bannerHeight = document.getElementsByClassName('MuiAlert-filledSuccess')[0]?.clientHeight || 0;
  const h = getReportIframeHeight(windowSize.height, bannerHeight, reportHasButtons);
  const isReportPage = location.pathname.startsWith('/reports');
  const cssClass = getCssClassname();

  if (reportHasEncounteredFatalError) {
    // TODO Create component for this ?
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Paper elevation={2} sx={{p: 3}}>
          <Typography variant="h6" textAlign="center">
            Une erreur inattendue est survenue. <br /> Veuillez rafraichir la page.
          </Typography>
          <Button onClick={() => navigate(0)} size="large" sx={{margin: '16px auto 0 auto', display: 'block'}} variant="contained" color="secondary">
            Rafraîchir
          </Button>
        </Paper>
      </Grid>
    );
  }

  const Container = isFullScreenMode ? BaseModal : Box;

  console.log({isFullScreenMode, Container});
  return (
    <Container
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: '#f5f5f5'
          }
        }
      }}
      overridesPaperProps={{
        boxShadow: 'none !important',
        background: '#f5f5f5 !important',
        justifyContent: !isReportLoaded || filtersLoading || isNavigatingToOtherReportPageToExportData ? 'center' : 'flex-start'
      }}
      height="100%"
      width="100%"
      withoutTitle
      isFullScreen
      open
      onClose={() => setIsFullscreenMode(false)}
    >
      <Grid
        id="report-container"
        container
        flexDirection="column"
        alignItems="center"
        justifyContent={!isReportLoaded || filtersLoading || isNavigatingToOtherReportPageToExportData ? 'center' : 'flex-start'}
        sx={{height: isReportPage ? '100%' : 0, maxHeight: h, opacity: isReportPage ? 1 : 0}}
        flexWrap="nowrap"
      >
        {!isReportLoaded && (
          <>
            <ReportLoadingSpinner loadingStep={reportLoadingStep} />
            <ExportDataLoadingSpinner />
          </>
        )}

        <FiltersLoadingSpinner />

        <PowerBIEmbed
          embedConfig={reportConfig}
          eventHandlers={
            new Map([
              ['bookmarkApplied', onBookmarkApplied],
              ['buttonClicked', onButtonClicked],
              ['dataSelected', onDataSelected],
              ['pageChanged', onPageChanged],
              ['loaded', endLoading],
              ['error', onError],
              ['saveAsTriggered', onSavedAs],
              // ['visualRendered', onVisualRendered],
              ['visualClicked', onVisualClicked]
            ])
          }
          getEmbeddedComponent={embedObject => {
            setSelectedReport(embedObject);
            setSelectedReportId(embedObject?.config.id);
          }}
          cssClassName={cssClass}
        />

        <LoadReportFiltersModal />

        {isReportLoaded && (
          <>
            <EditableReportButtons />
            <SaveEditedReportModal />

            {module === DEFAULT_PROJECTS_SCHEMAS.previ && <DefiPreviButtonsAndModals />}

            {module === DEFAULT_PROJECTS_SCHEMAS.gestion && <DefiGestionButtons />}
          </>
        )}
      </Grid>
    </Container>
  );
};

EmbedReport.defaultProps = {
  lastPageBeforeReload: null,
  module: null
};

EmbedReport.propTypes = {
  reportConfig: shape({}).isRequired,
  lastPageBeforeReload: shape({}),
  module: string
};

export default EmbedReport;
